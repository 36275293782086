<template lang="html">

    <section class="banner-links d-flex  aife" :banner="titleBanner" :id="this.id">
        <div class="container column-center container-main-link">

            <agile :options="myOptions" ref="carousel" class="d-flex jcsb aife container-links container w-100">
        

                <router-link :class="[$route.params.slug == 'integra' ? 'link-box active' : 'link-box']"
                @mousemove="manual('integra')" @mouseleave="reanudar()"

                    :to="{ name: 'projects', params: { slug: 'integra' } }">
                    {{ $t('projects.integra.title') }}
                    <p class="description">
                        {{ $t('projects.integra.header') }}
                    </p>
                </router-link>
                <router-link :class="[$route.params.slug == 'a-la-par' ? 'link-box active' : 'link-box']"
                @mousemove="manual('a-la-par')" @mouseleave="reanudar()"

                    :to="{ name: 'projects', params: { slug: 'a-la-par' } }">
                    {{ $t('projects.a-la-par.title') }}
                    <p class="description">
                        {{ $t('projects.a-la-par.header') }}
                    </p>
                </router-link>
                <router-link :class="[$route.params.slug == 'altius' ? 'link-box active' : 'link-box']"
                    @mousemove="manual('altius')" @mouseleave="reanudar()"
                    :to="{ name: 'projects', params: { slug: 'altius' } }">
                    {{ $t('projects.altius.title') }}
                    <p class="description">
                        {{ $t('projects.altius.header') }}
                    </p>
                </router-link>

            </agile>
        </div>
        <button @click="$refs.carousel.goToNext()" class="nextSlide" title="Next Slide"></button>
        <button @click="$refs.carousel.goToPrev()" class="prevSlide" title="Prev Slide"></button>
    </section>

</template>
  
<script lang="js">
import { contentStore } from '@/stores/contents'
import {
    VueAgile
} from "vue-agile";
export default {
    name: 'banner-links',
    components: {
        agile: VueAgile,
    },
    props: [],
    mounted() {


    },
    created() {
        if (this.timerId == false) {
            this.timerId = setInterval(() => {
                var ids = ['altius', 'integra','a-la-par']

                this.id = ids[this.index];
                this.index++;
                if (this.index == 2) {
                    this.index = 0;
                }
            }, 5000)
        }

    },
    setup() {
        const store = contentStore();
        return {
            store
        }
    },

    data: () => ({
        banner: null,
        id: null,
        timerId: false,
        index: 0,
        myOptions: {
            responsive: [{
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                    navButtons: false,
                    centerMode: true,
                    unagile: false,
                },
            }, {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                    navButtons: false,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                    navButtons: false,

                },
            }, {
                breakpoint: 0,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    navButtons: false,

                },
            },
            ],
        },
    }),
    methods: {
        manual(x) {
            this.id = x;
            clearInterval(this.timerId);
            this.timerId = true;
        },
        reanudar() {

            this.timerId = false;
            if (this.timerId == false) {
                this.timerId = setInterval(() => {
                    var ids = ['altius', 'integra','a-la-par']

                    this.id = ids[this.index];
                    this.index++;
                    if (this.index == 2) {
                        this.index = 0;
                    }
                }, 5000)
            }
        }



    },
    computed: {

    }
}


</script>
  
<style scoped lang="scss">
.banner-links {}
</style>